<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <div v-if="toaster">
      <ToasterU v-if="form.id" />
      <ToasterA v-else />
    </div>
    <ToasterAc v-if="toasterac" />
    <ToasterDe v-if="toasterde" />
    <ToasterD v-if="toasterd" />

    <Loader1 v-if="loader" />
    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>

    <div class="container-fluid mt-6" style="background-color: white">
      <br />

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Privacy Policy</h2>
        <h2 v-else style="font-size: 1.5rem">Add Privacy Policy</h2>
      </div>

      <hr />
      <form
        method="post"
        @submit.prevent="AddActivity"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
        />
        <label class="lablestyle mt-2">
          Add Privacy Policy<span class="text-danger"> *</span></label
        >
        <ckeditor
          :editor="editor"
          v-model="form.privacy_policy"
          :config="editorConfig"
        ></ckeditor>

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.privacy_policy"
          >{{ errors.privacy_policy[0] }}</span
        >
        <br />

        <div class="text-right">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="mb-5 btn btn-primary m-0"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import ToasterA from "../ToasterA";
import ToasterU from "../ToasterU";
import Swal from "sweetalert2";
import ToasterAc from "../ToasterAc";
import ToasterDe from "../ToasterDe";
import ToasterD from "../ToasterD";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    Loader1,
    ToasterA,
    ToasterU,
    ToasterDe,
    ToasterAc,
    ToasterD,
  },

  data() {
    return {
      errors: [],

      toasterac: false,
      toasterde: false,
      toasterd: false,
      loader: false,

      imgUrl: this.$appName,
      toaster: false,
      img_folder: "/images/",
      base_url: this.$appName,

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },

      form: new Form({
        id: "",
        privacy_policy: "",
      }),
    };
  },

  created() {
    axios
      .get(this.$appName + `/api/setting/Setting/${this.$route.params.id}`)
      .then((res) => {
        if (res.data.id != undefined) {
          this.form = res.data;
          console.log("dd", this.form);
        }
      });
  },

  mounted() {},

  methods: {
    async AddActivity() {
          this.loader = true;

      let data = new FormData();
      data.append("id", this.form.id);
      data.append("privacy_policy", this.form.privacy_policy);
      let addNewActivity = await axios
        .post(this.$appName + "/api/setting/storePrivacy", data)
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
      if (addNewActivity) {
        this.toaster = true;
        this.toasterprop = true;
        this.loader = false;

        setTimeout(() => {
          location.reload();
        }, 0);
      }
    },
  },
};
</script>

<style></style>

<template>
  <div id="app" class="container" style="background-color: #f7fafc">
    <Loader1 v-if="loader" />

    <h1 class="text-center"></h1>
    <div class="row">
      <div class="col">
        <projects-table title="Light Table"></projects-table>
      </div>
    </div>
    <div></div>
    <div class="container-fluid mt-6" style="background-color: white">
      <div class="row">
        <div class="col-md-4 mb-2 mt-2 pl-1">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            style="
              background: #1f7dad;
              border-color: #1f7dad;
              border-radius: 26px;
              font-size: 13px;
              padding: 6px 11px 6px 11px;
            "
            @click="$router.go(-1)"
          >
            Back
          </button>
        </div>

        <div class="col-md-8 text-right mt-2 mb-2 p-0">
          <base-button
            v-if="form.id"
            type="button"
            class="btn btn-primary btn-sm"
            style="
              background: #1f7dad;
              border-color: #1f7dad;
              border-radius: 26px;
              font-size: 13px;
              padding: 6px 11px 6px 11px;
            "
            @click="goTo"
            >Add</base-button
          >
        </div>
      </div>

      <div class="mt-2">
        <h2 v-if="form.id" style="font-size: 1.5rem">Edit Challenge</h2>
        <h2 v-else style="font-size: 1.5rem">Add Challenge</h2>
      </div>
      <hr />
      <form
        method="post"
        @submit.prevent="AddChallenge"
        @keydown="form.onKeydown($event)"
        enctype="multipart/form-data"
      >
        <base-input
          v-model="form.id"
          type="hidden"
          name="id"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <base-input
          v-model="sublevelID"
          type="hidden"
          name="sublevelID"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />

        <label class="lablestyle">
          Select Category<span class="text-danger"> *</span></label
        >
        <multiselect
          v-model="form.selectedd"
          :options="categories"
          :multiple="false"
          @search-change="onChange"
          :searchable="true"
          :close-on-select="true"
          placeholder="Select word"
          label="name"
          track-by="id"
        >
        </multiselect>

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.category_id"
          >{{ errors.category_id[0] }}</span
        >

        <br />

        <label class="lablestyle">
          Name<span class="text-danger"> *</span></label
        >

        <base-input
          v-model="form.name"
          type="text"
          name="name"
          placeholder="Enter Name"
          style="margin-bottom: 1px"
        />
        <span class="text-danger" style="font-size: 13px" v-if="errors.name">{{
          errors.name[0]
        }}</span>
        <br />

        <label class="lablestyle">
          Answer<span class="text-danger"> *</span></label
        >

        <base-input
          v-model="form.answer"
          type="text"
          name="answer"
          placeholder="Enter Answer"
          style="margin-bottom: 1px"
        />
        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.answer"
          >{{ errors.answer[0] }}</span
        >
        <br />
        <label class="lablestyle">
          Image A<span class="text-danger"> *</span></label
        >

        <input
          style="margin-bottom: 1px"
          :v-model="form.imageA"
          type="file"
          class="form-control"
          name="Image A"
          id="image1"
          placeholder=""
          accept="image/*"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.image1"
          >{{ errors.image1[0] }}</span
        >
        <br />
        <div v-if="form.id">
          <img
            class="imgg"
            :src="base_url + img_folder + form.image1"
            style="margin-top: -15px"
            alt=""
          />
          <br />
        </div>
        <label class="lablestyle mt-2">
          Image B<span class="text-danger"> *</span></label
        >
        <input
          style="margin-bottom: 1px"
          :v-model="form.imageB"
          type="file"
          class="form-control"
          name="Image B"
          id="image2"
          placeholder=""
          accept="image/*"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.image2"
          >{{ errors.image2[0] }}</span
        >
        <br />
        <div v-if="form.id">
          <img
            class="imgg"
            :src="base_url + img_folder + form.image2"
            style="margin-top: -15px"
            alt=""
          />
          <br />
        </div>

        <label class="lablestyle mt-2">
          Image C<span class="text-danger"> *</span></label
        >
        <input
          style="margin-bottom: 1px"
          :v-model="form.imageC"
          type="file"
          class="form-control"
          name="Image C"
          id="image3"
          placeholder=""
          accept="image/*"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.image3"
          >{{ errors.image3[0] }}</span
        >
        <br />
        <div v-if="form.id">
          <img
            class="imgg"
            :src="base_url + img_folder + form.image3"
            style="margin-top: -15px"
            alt=""
          />
          <br />
        </div>
        <label class="lablestyle mt-2">
          Image D<span class="text-danger"> *</span></label
        >
        <input
          style="margin-bottom: 1px"
          :v-model="form.imageD"
          type="file"
          class="form-control"
          name="Image D"
          id="image4"
          placeholder=""
          accept="image/*"
        />

        <span
          class="text-danger"
          style="font-size: 13px"
          v-if="errors.image4"
          >{{ errors.image4[0] }}</span
        >
        <br />
        <div v-if="form.id">
          <img
            class="imgg"
            :src="base_url + img_folder + form.image4"
            style="margin-top: -15px"
            alt=""
          />
        </div>
        <div class="text-right" style="margin-top: -8px">
          <button
            type="submit"
            style="background: #1f7dad; border-radius: 26px"
            class="my-3 btn btn-primary"
            :disabled="form.busy"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <br />
    <br />
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Form from "vform";
import axios from "axios";
import Loader1 from "../Loader1";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  components: {
    Loader1,
    Multiselect,
  },

  data() {
    return {
      category: [],
      char_names: [],
      alertText: "",
      alertRowNum: "",
      alertSuccess: "",
      checkedNames: [],
      alertDuplicate: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      pageOptions: [10, 25, 50, 100],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      isDelete: true,
      items: "",
      levelLenght: null,
      selected: [],
      allSelected: false,
      countSubLevel: null,
      count: 0,
      status: "",
      wordss: [],
      selectedd: "",
      categories: [],
      errors: [],
      category_id: [],
      loader: false,
      alert: false,
      sublevelID: "",

      activities: null,
      img_folder: "/images/",
      base_url: this.$appName,
      form: new Form({
        id: "",
        selectedd: "",
        name: "",
        imageA: "",
        imageB: "",
        imageC: "",
        imageD: "",
        answer: "",
      }),
    };
  },
  mounted() {
    this.sublevelID = localStorage.getItem("sublevelID");
  },
  created() {
    if (this.$route.params.id != undefined) {
      axios
        .get(
          this.$appName + `/api/challenge/Challenge/${this.$route.params.id}`
        )
        .then((res) => {
          this.form = res.data;
          console.log("sdsd", res.data);
          //   this.form.selectedd = res.data.category_id
        });
    }
  },

  methods: {
    onChange(query) {
      if (query.length > 2) {
        axios
          .get(
            this.$appName +
              `/api/challenge/showCtagory/${query}/${this.form.category_id}`
          )
          .then((res) => {
            this.categories = res.data;
          });
      }
    },

    async AddChallenge() {
      //   this.loader = true;
      let data = new FormData();
      data.append("id", this.form.id);
      data.append("sublevel_id", this.sublevelID);
      data.append("category_id", this.form.selectedd.id);
      data.append("name", this.form.name);
      data.append("answer", this.form.answer);

      data.append("image1", document.getElementById("image1").files[0]);
      data.append("image2", document.getElementById("image2").files[0]);
      data.append("image3", document.getElementById("image3").files[0]);
      data.append("image4", document.getElementById("image4").files[0]);

      await axios
        .post(this.$appName + "/api/challenge/Challenge", data)
        .then((response) => {
          this.loader = false;
          this.$router.push({
            name: "View challenge",
            params: { id: this.sublevelID },
          });
          console.log(response);
        })
        .catch((error) => {
          this.loader = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>

<style src=""></style>
